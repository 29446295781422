import { PagePermit } from '../static/const'

export default function (currentpath: string) {
  const currentUser = useLoginAccount()
  const targetPage = PagePermit.find(v => currentpath.split(/\//).includes(v.path))

  if (currentUser.value && targetPage && !targetPage.role.includes(currentUser.value.role as AdminRole)) {
    return false
  }

  return true
}
